import { Box, Paper, Typography, /*useTheme*/ } from "@mui/material";
// import { tokens } from '../../theme';

function ExpertiseCard(props) {

	const { title, text, icon } = props

	// const theme = useTheme();
    // const colors = tokens(theme.palette.mode);

	return (
		<Paper 
			elevation={10} 
			sx={{
				width: '100%', 
				m: '20px', 
				//backgroundColor: colors.grey[300]
				background: 'linear-gradient(135deg, #D9D9D9, #FAFAFA 50%)'
			}}
		>
			<Box 
				display='flex' flexDirection='column'
				height="320px"
				alignItems='center'
				p='20px'
			>
				
				<Typography 
					variant="h4" color='primary.dark'
					display='flex' textAlign='center' alignItems='center' 
					height='30%'
				>
					{title}
				</Typography>
				<Box 
					display='flex' textAlign='center' alignItems='center'
					height='30%'
				>
					{icon}
				</Box>
				<Typography 
					variant="h6" color='primary.dark' 
					display='flex' textAlign='center' alignItems='center' 
					height='40%'
				>
					{text}
				</Typography>
			</Box>
		</Paper>
	);
}

export default ExpertiseCard;
