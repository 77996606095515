import { Box, useTheme } from "@mui/material";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { tokens } from "../../theme";

function Link(props) {
  const { label, page, selectedPage, setSelectedPage } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      // borderBottom={page === selectedPage ? 2 : ""}
      // borderColor={page === selectedPage ? colors.primary[500] : ""}
      // underlining selected
    >
      <AnchorLink
        href={`#${page}`}
        offset='100px'
        onClick={() => setSelectedPage(page)}
        style={{
          textDecoration: "none",
          color: page === selectedPage ? colors.grey[500] : colors.grey[700],
        }} // changing text colour of selected
      >
        {label}
      </AnchorLink>
    </Box>
  );
}

export default Link;
