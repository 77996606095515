import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material'
import { motion } from "framer-motion"
import bg from '../../assets/background/home_bg.jpg'


function Home(props) {

    const { setSelectedPage } = props

    const { t } = useTranslation();

    return (
        <section id='home' className='scene'>
            < Box
                component={motion.div}
                onViewportEnter={() => setSelectedPage('home')}
                display='flex'  
                height={{xs: '101vh'}}
                alignItems='center'
                justifyContent='center'
                sx={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundPositionX: '100%'
                }}
            >
                <Box
                    width='80%'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}></Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h0" color='secondary' fontSize={{xs: '40px', sm:'50px'}} >
                                {t('scene.home.header_1')}
                            </Typography>
                            <Typography variant="h6" mt="30px" >
                                {t('scene.home.text_1')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </section>
    )
}

export default Home