import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material'
import useMediaQuery from '../../hooks/useMediaQuery';
import logo from '../../assets/logos/navbar_logo.svg'
import MailOutlineIcon from '@mui/icons-material/MailOutline';



function Contact(props) {

    const { setSelectedPage } = props

    const { t } = useTranslation();
    const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)')

    return (
        <section id='contact' className='scene'>
            <Box 
                component={motion.div}
                onViewportEnter={() => setSelectedPage('contact')}
                display='flex'  
                width='80%'
                height="150px"
                alignItems='center'
                justifyContent='space-between'
                flexDirection={{xs: 'column', sm: 'row'}}
                mx='auto'
                py='20px'
            >
                <img alt='logo' src={logo} height= { isAboveMediumScreens ? '30px' : '20px' }/>
                <Box>
                    <Typography variant="h5" mb='10px' color='secondary'>
                        {t('label.contact')}
                    </Typography>
                    <Box display='flex' alignItems='center' gap='10px'>
                        <MailOutlineIcon sx={{ fontSize: '24px' }} />
                        <Typography variant="h6">
                            contact@midnightcode.pl
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </section>
    )
}

export default Contact