import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Box, IconButton, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import useMediaQuery from '../../hooks/useMediaQuery';
import LanguageSwitch from '../switches/LanguageSwitch';
import Link from './Link';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import navbar_logo from '../../assets/logos/navbar_logo.svg';


function Navbar(props) {

    const { isTopOfPage, selectedPage, setSelectedPage } = props

    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMenuToggled, setIsMenuToggled] = useState(false)
    const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)')

    const links = [
        {
            label: t('navbar.link.home'),
            page: 'home' 
        },
        {
            label: t('navbar.link.web_design'),
            page: 'web_design' 
        },
        {
            label: t('navbar.link.apps'),
            page: 'apps' 
        },
        {
            label: t('navbar.link.engineering'),
            page: 'engineering' 
        },
        
        {
            label: t('navbar.link.contact'),
            page: 'contact' 
        },
    ]

    const linksList = links.map((link, index) => 
        <Link
            key={index}
            label={link.label}
            page={link.page}
            selectedPage={selectedPage} 
            setSelectedPage={setSelectedPage}
        />
    )

    
    return (
        <>
            <Box 
                position='fixed' top='0px' zIndex='30'
                display='flex' alignItems='center'
                width='100%'
                height= { isAboveMediumScreens ? '100px' : '70px' }
                backgroundColor= { isTopOfPage ? alpha('#030200', 0.8) : colors.background.main }
            >
                <Box
                    display='flex' alignItems='center' justifyContent='space-between'
                    width='80%'
                    mx='auto'
                >
                    <img alt='logo' src={navbar_logo} height= { isAboveMediumScreens ? '30px' : '20px' }/>

                    {isAboveMediumScreens ? (
                        <>
                            <Box 
                                display='flex' alignItems='center'
                                gap='30px'
                                fontSize='20px'
                            >
                                {linksList}
                            </Box>
                            <Box
                                display='flex' alignItems='center' justifyContent='center'
                            >
                                <LanguageSwitch />
                            </Box>
                        </>
                    ) : (
                        <IconButton 
                            color="primary"
                            onClick={() => setIsMenuToggled(!isMenuToggled)}
                        >
                            <MenuRoundedIcon sx={{ fontSize: '36px', color: colors.primary[500] }}/>
                        </IconButton>
                    )}
                </Box>
            </Box>

            {/* MOBILE MENU MODAL */}
            {!isAboveMediumScreens && isMenuToggled ? (
                <Box
                    position='fixed' right='0px' top='0px' minHeight='100%' width='250px' zIndex='40'
                    display='flex' flexDirection='column' justifyContent='space-between'
                    sx={{ backgroundColor: colors.background.mobileNav }}
                    gap='50px'
                >
                    <Box>
                        <Box display='flex' justifyContent='flex-end' padding='20px'>
                            <IconButton
                                color="primary"
                                onClick={() => setIsMenuToggled(false)}
                            >
                                <CloseRoundedIcon sx={{ fontSize: '36px', color: colors.primary[500] }}/>
                            </IconButton>
                        </Box>
                        <Box 
                            display='flex' flexDirection='column' fontSize='22px' alignItems='center'
                            gap='20px'
                        >
                            {linksList}
                        </Box>
                    </Box>
                    <Box 
                        display='flex' justifyContent='center'
                        mb='100px'
                    >
                        <LanguageSwitch />
                    </Box>
                </Box>
            ) : null
            }
        </>
    )
}

export default Navbar 