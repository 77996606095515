import { Box, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function DesignCard() {

	const theme = useTheme();
    const colors = tokens(theme.palette.mode);

	return (
		<Box 
			height="280px" width='100%' maxWidth='350px'
			m='10px'
			display='flex'
			alignItems='center'
			justifyContent='center'
			sx={{background: 'linear-gradient(135deg, #030200, #171717)'}}
		>
			<MoreHorizIcon sx={{ fontSize: '100px', color: colors.primary[500] }} />
		</Box>
	);
}

export default DesignCard;
