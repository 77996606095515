import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme';
import bg from '../../assets/background/engineering_bg.jpg'
import ExpertiseCard from "./ExpertiseCard"
import {ReactComponent as Blueprint} from '../../assets/icons/blueprint.svg';
import {ReactComponent as Machine} from '../../assets/icons/machine.svg';
import {ReactComponent as Programming} from '../../assets/icons/programming.svg';
import {ReactComponent as Project} from '../../assets/icons/project.svg';


function Engineering(props) {

    const { setSelectedPage } = props

    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const gridItemStyle={display: 'flex', alignItems: 'center', justifyContent: 'center'}

    return (
        <section id='engineering' className='scene'>
            <Box 
                component={motion.div}
                onViewportEnter={() => setSelectedPage('engineering')}
                display='flex'  
                alignItems='center'
                justifyContent='center'
                py='200px'
                sx={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                }}
            >
                <Box
                    width='80%'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    mx='auto'
                >
                    <Typography variant="h0" color='primary.dark' mb='50px' fontSize='50px' textAlign='center'>
                        {t('scene.engineering.header_1')}
                    </Typography>
                    <Grid container >
                        <Grid 
                            item xs={12} md={6} lg={3} 
                            sx={gridItemStyle}
                        >
                            <ExpertiseCard 
                                title={t('scene.engineering.expertise.title_1')}
                                text={t('scene.engineering.expertise.text_1')}
                                icon={<Project fill={colors.primary[500]} transform='scale(1.5)' />}
                            />
                        </Grid>
                        <Grid 
                            item xs={12} md={6} lg={3} 
                            sx={gridItemStyle}
                        >
                            <ExpertiseCard 
                                title={t('scene.engineering.expertise.title_2')}
                                text={t('scene.engineering.expertise.text_2')}
                                icon={<Blueprint stroke={colors.primary[500]} transform='scale(1.5)' />}
                            />
                        </Grid>
                        <Grid 
                            item xs={12} md={6} lg={3} 
                            sx={gridItemStyle}
                        >
                            <ExpertiseCard 
                                title={t('scene.engineering.expertise.title_3')}
                                text={t('scene.engineering.expertise.text_3')}
                                icon={<Machine fill={colors.primary[500]} transform='scale(1.5)' />}
                            />
                        </Grid>
                        <Grid 
                            item xs={12} md={6} lg={3} 
                            sx={gridItemStyle}
                        >
                            <ExpertiseCard 
                                title={t('scene.engineering.expertise.title_4')}
                                text={t('scene.engineering.expertise.text_4')}
                                icon={<Programming stroke={colors.primary[500]} transform='scale(1.5)' />}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </section>
    )
}

export default Engineering