import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";


// colors
export const tokens = (mode) => ({
    ...(mode === 'light'
        ? {
            grey: {
                100: "#FAFAFA",
                200: "#F2F2F2",
                300: "#E6E6E6",
                500: "#D9D9D9",
                700: "#999999",
            },
            primary: {
                500: "#1CA698",
                700: "#11665E",
                900: "#09332E"
            },
            secondary: {
                500: "#DBF288",
            },
            background: {
                light: "#171717",
                main: "#030200",
                mobileNav: "#1e1e24"
            }
        }: {}
    )
})

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);

    return {
        palette: {
            mode: mode,
            ...(mode === 'light'
                ? {
                    primary: {
                        dark: colors.primary[900],
                        main: colors.primary[500],
                    },
                    secondary: {
                        main: colors.secondary[500],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[300],
                    },
                    background: {
                        default: colors.background.main
                    },
                    text:{
                        primary: colors.grey[300]
                      }
                } : {}
            )
        },
        typography: {
            fontFamily: ['Inter', 'sans-serif'].join(','),
            fontSize: 14,
            h0: {
                fontFamily: ['Amiko', 'sans-serif'].join(','),
                fontSize: 40,
            },
            h1: {
                fontFamily: ['Amiko', 'sans-serif'].join(','),
                fontSize: 32,
            },
            h2: {
                fontFamily: ['Amiko', 'sans-serif'].join(','),
                fontSize: 26,
            },
            h3: {
                fontFamily: ['Amiko', 'sans-serif'].join(','),
                fontSize: 22,
            },
            h4: {
                fontFamily: ['Amiko', 'sans-serif'].join(','),
                fontSize: 20,
            },
            h5: {
                fontFamily: ['Inter', 'sans-serif'].join(','),
                fontSize: 18,
            },
            h6: {
                fontFamily: ['Inter', 'sans-serif'].join(','),
                fontSize: 14,
            },
        }
    };
};

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => {}
})

export const useMode = () => {
    const [mode, setMode] = useState('light');

    const colorMode = useMemo(() => ({
        toggleColorMode: () =>
            setMode((prev) => (prev === 'light' ? 'dark' : 'light'))
    }), []);

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])

    return [theme, colorMode];
}
