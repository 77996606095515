import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
import { Box, Grid, Typography } from '@mui/material'
import DesignCard from "./DesignCard"



function WebDesign(props) {

    const { setSelectedPage } = props

    const { t } = useTranslation();

    const gridItemStyle={display: 'flex', alignItems: 'center', justifyContent: 'center'}

    return (
        <section id='web_design' className='scene'>
            <Box 
                component={motion.div}
                onViewportEnter={() => setSelectedPage('web_design')}
                width='80%'
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                mx='auto'
                my='200px'
            >
                <Typography variant="h4" color='secondary' mb='50px'>
                    {t('scene.web_design.header_1')}
                </Typography>

                <Grid container 
                >
                    <Grid 
                        item xs={12} md={6} lg={3} 
                        sx={gridItemStyle}
                    >
                        <DesignCard></DesignCard>
                    </Grid>
                    <Grid 
                        item xs={12} md={6} lg={3} 
                        sx={gridItemStyle}
                    >
                        <DesignCard></DesignCard>
                    </Grid>
                    <Grid 
                        item xs={12} md={6} lg={3} 
                        sx={gridItemStyle}
                    >
                        <DesignCard></DesignCard>
                    </Grid>
                    <Grid 
                        item xs={12} md={6} lg={3} 
                        sx={gridItemStyle}
                    >
                        <DesignCard></DesignCard>
                    </Grid>
                </Grid>
            </Box>
        </section>
    )
}

export default WebDesign