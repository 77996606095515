import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Button, Menu, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import poland_flag_icon from '../../assets/country_flag_icons/poland_flag_icon.svg'
import united_kingdom_flag_icon from '../../assets/country_flag_icons/united_kingdom_flag_icon.svg'


const buttons = [
    {
        label: 'English',
        language: 'en',
        icon: <Box component="img" src={united_kingdom_flag_icon} sx={{ width: { xs: 40, md: 30 }, height: { xs: 40, md: 30 } }} />
    },
    {
        label: 'Polski',
        language: 'pl',
        icon: <Box component="img" src={poland_flag_icon} sx={{ width: { xs: 40, md: 30 }, height: { xs: 40, md: 30 } }} />
    },
]

function LanguageSwitch() {

    const { i18n } = useTranslation();
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    return (
        <>
            {buttons.filter(button => button.language === i18n.resolvedLanguage).map((button, id) =>
                <Button
                    key={id}
                    variant='outlined'
                    icon={button.icon}
                    onClick={(e) => setAnchor(e.currentTarget)}
                    sx={{
                        minWidth: 0,
                        padding: '10px',
                        border: 0,
                        '&:hover': {
                            border: 0,
                        }
                    }}
                >
                    {button.icon}
                </Button>
            )}

            <Menu
                anchorEl={anchor}
                open={open}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    '& .MuiList-root': {
                        padding: '0px',
                    },
                }}
                PaperProps={{ sx: { backgroundColor: colors.background.light } }}
                disableScrollLock={true}
            >
                <Box display='flex' flexDirection='column' gap='10px' p='10px'>
                    {buttons.filter(button => button.language !== i18n.resolvedLanguage).map((button, id) =>
                        <Button
                            key={id}
                            variant='outlined'
                            icon={button.icon}
                            onClick={() => { i18n.changeLanguage(button.language); setAnchor(null) }}
                            sx={{
                                minWidth: 0,
                                padding: 0,
                                border: 0,
                                '&:hover': {
                                    border: 0,
                                }

                            }}
                        >
                            {button.icon}
                        </Button>
                    )}
                </Box>
            </Menu>
        </>
    )
}

export default LanguageSwitch





