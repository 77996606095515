import { useEffect, useState } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import Navbar from './components/navbar/Navbar';
import Home from './scenes/home/Home'
import Apps from './scenes/apps/Apps';
import Contact from './scenes/contact/Contact';
import Engineering from './scenes/engineering/Engineering';
import WebDesign from './scenes/web_design/WebDesign';


function App() {

  const [theme, colorMode] = useMode();
  const [selectedPage, setSelectedPage] = useState('home')
  const [isTopOfPage, setIsTopOfPage] = useState(true)

  // background of navbar:
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true)
        setSelectedPage('home')
      }
      if (window.scrollY !== 0) {
        setIsTopOfPage(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Navbar 
            isTopOfPage={isTopOfPage}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
          <Box width='100%'>
            <Home setSelectedPage={setSelectedPage} />
            <WebDesign setSelectedPage={setSelectedPage} />
            <Apps setSelectedPage={setSelectedPage} />
            <Engineering setSelectedPage={setSelectedPage} />
            <Contact setSelectedPage={setSelectedPage} />
          </Box>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
