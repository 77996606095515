import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Typography /*useTheme*/ } from "@mui/material";
//import { tokens } from '../../theme';
import { motion } from "framer-motion";
import laptop from "../../assets/mockups/harmony_laptop.svg";
import screen from "../../assets/mockups/harmony_screen.svg";

function Apps(props) {
  const { setSelectedPage } = props;

  const { t } = useTranslation();

  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  const gridItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    my: "30px",
  };

  return (
    <section id="apps" className="scene">
      <Box
        component={motion.div}
        onViewportEnter={() => setSelectedPage("apps")}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mx="auto"
        py="100px"
        sx={{ background: "linear-gradient(90deg, #030200 30%, #171717)" }}
      >
        <Box
          width="80%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container>
            <Grid item xs={12} md={12} lg={4} sx={gridItemStyle}>
              <Box>
                <Typography variant="h1" color="secondary" fontSize="50px">
                  {t("scene.apps.header_1")}
                </Typography>
                <Typography variant="h6" mb="50px">
                  {t("scene.apps.text_1")}
                </Typography>
                <Box
                  display="flex"
                  justifyContent={{ xs: "center", sm: "left" }}
                  pb={{ xs: "50px", sm: "30px" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{
                      borderRadius: "50px",
                      p: "15px 60px",
                    }}
                    href="https://www.i-mes.cloud"
                  >
                    {t("button.i_mes")}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={gridItemStyle}>
              <img alt="screen_mockup" src={screen} height="280px" />
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={gridItemStyle} pl="40px">
              <img alt="laptop_mockup" src={laptop} height="280px" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </section>
  );
}

export default Apps;
